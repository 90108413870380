{
  "support":"Support",
  "help_center": "Help Center",
  "submit_feedback": "Submit feedback",
  "select_what_you_want_to_feedback": "Please select a problem",
  "use_problem": "Usage problem",
  "feature_suggestions": "Feature suggestion",
  "business_cooperation": "Business cooperation",
  "select_type_of_product_for_feedback": "Please select a product",
  "App_wallet": "Wallet",
  "extension_wallet": "Web Wallet",
  "version_number": "Version",
  "version_number_sub_app":"Click on the avatar in the upper right corner of the ONTO plug-in wallet-About us-view the version number～",
  "version_number_sub_ext":"Click on the avatar in the upper left corner of the ONTO APP wallet-About us-view the version number～",
  "os": "Operating system",
  "phone_model": "Phone model",
  "problem_types": "Category",
  "title": "Title",
  "describe": "Description",
  "email": "Email address",
  "email_sub": "Please provide an email address and we will get back to you as soon as we can.",
  "submit": "Submit",
  "please_enter_title": "Please enter a title",
  "please_enter_content": "Please enter content",
  "please_enter_email": "Please input your email",
  "please_enter_phone_model": "Please enter your cellphone model",
  "please_select_problem_types": "Please select the question type",
  "sending_failure": "Failed to send",
  "transfer_issue": "Unable to transfer",
  "no_record_of_assets": "No record of assets",
  "payment_failure": "Failed to receive",
  "wallet_import": "Wallet import",
  "hardware_wallet":"Hardware wallet",
  "backup_mnemonic":"Backup mnemonic",
  "third_party_issues":"Third-party application issues",
  "other":"Other",
  "please_describe_recommendations":"Please describe your suggestions for product features",
  "please_introduce_cooperate":"Please introduce your company and collaboration proposal",
  "tips_for_successful_training":"Your request was successfully submitted. Thank you for your support. We will respond as soon as possible..",
  "before":"before",
  "cooperation_describe_sub":"Please share your product feature suggestions",
  "issue_describe_sub":"Please describe your issue or question. Our support staff will reply as soon as possible.",
  "suggest_describe_sub": "Please share your product feature suggestions",
  "email_format_error":"Email format error",
  "upload_screenshot":"Upload screenshot",
  "upload_screenshot_sub":"Upload a screenshot here as an additional description. PNG and JPG formats are supported. You can upload up to 3 images. The image size should be under 5 MB."
}