import { createRouter, createWebHistory, RouteRecordRaw } from "vue-router";
import Home from "../views/Home/index.vue";

const routes: Array<RouteRecordRaw> = [
  {
    path: "/",
    name: "Home",
    component: Home,
  },
  {
    path: "/issue",
    name: "Issue",
    component: () => import("../views/Issue/index.vue"),
  },
  {
    path: "/suggest",
    name: "Suggest",
    component: () => import("../views/Suggest/index.vue"),
  },
  {
    path: "/cooperation",
    name: "Cooperation",
    component: () => import("../views/Cooperation/index.vue"),
  },
];

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes,
});

export default router;
