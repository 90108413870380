<template>
  <header class="header">
    <div class="g-safe-area container">
      <div class="left">
        <img class="logo" src="./img/logo.svg" alt="" />
        <div class="line"></div>
        <h5 class="text">{{ t("support") }}</h5>
      </div>
      <div class="right">
        <div class="to_books" @click="toBooks">{{ t("help_center") }}</div>
        <!-- <LangSwitcher /> -->
      </div>
    </div>
  </header>
</template>

<script lang="ts">
// import LangSwitcher from "@/components/LangSwitcher.vue";
import { defineComponent } from "vue";
import { useI18n } from "vue-i18n";

export default defineComponent({
  name: "Header",
  setup() {
    const { t, locale } = useI18n();
    console.log(locale.value);
    const toBooks = () => {
      location.href =
        locale.value === "zh"
          ? "https://ontology-1.gitbook.io/onto-cn/"
          : "https://ontology-1.gitbook.io/onto/";
    };
    return {
      t,
      locale,
      toBooks,
    };
  },
  components: {},
});
</script>

<style scoped lang="scss">
.header {
  z-index: 1;
  position: fixed;
  top: 0;
  right: 0;
  left: 0;
  background: #000;
}

.container {
  height: var(--header-height);
  max-width: 1040px;
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.logo {
  height: 42px;
}

.line {
  margin: 0 20px;
  height: 24px;
  width: 1px;
  background: #ffffff;
}

.text {
  white-space: nowrap;
  font-size: 20px;
  line-height: 24px;
  color: #ffffff;
}

.header__lang {
  margin-left: auto;
}
.to_books {
  color: #fff;
  font-size: 16px;
  height: 24px;
  line-height: 24px;
  font-weight: 400;
  cursor: pointer;
  margin-right: 35px;
}
.left,
.right {
  display: flex;
  align-items: center;
}
@media screen and (max-width: 750px) {
  .left {
    .logo {
      height: 24px;
    }
    .line {
      height: 14px;
      margin: 0 10px;
    }
    .text {
      font-size: 14px;
      font-weight: 600;
    }
  }
  .to_books {
    font-size: 14px;
    margin-right: 20px;
  }
}
</style>
