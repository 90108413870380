<template>
  <Header />
  <div class="min_height">
    <router-view />
  </div>
  <Footer />
</template>
<script lang="ts">
import { defineComponent } from "vue";
import Header from "@/components/Header/index.vue";
import Footer from "@/components/Footer/index.vue";

export default defineComponent({
  name: "App",
  components: {
    Header,
    Footer,
  },
});
</script>

<style>
#app {
  min-height: 100vh;
  background: #f6f6f7;
  font-family: "PingFang SC", 微软雅黑, 黑体, Microsoft YaHei, SimHei,
    "Open Sans", sans-serif;
}
.min_height {
  min-height: calc(100vh - 280px);
}
</style>
