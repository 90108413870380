{
  "support":"支持",
  "help_center": "帮助中心",
  "submit_feedback": "提交反馈",
  "select_what_you_want_to_feedback": "请选择您要反馈的内容",
  "use_problem": "使用问题",
  "feature_suggestions": "功能建议",
  "business_cooperation": "商务合作",
  "select_type_of_product_for_feedback": "请选择您要反馈的产品类型",
  "App_wallet": "App钱包",
  "extension_wallet": "插件钱包",
  "version_number": "版本号",
  "version_number_sub_app":"点击ONTO插件钱包右上角头像-关于我们-查看版本号",
  "version_number_sub_ext":"点击ONTO APP左上角头像-关于我们-查看版本号",
  "os": "操作系统",
  "phone_model": "手机型号",
  "problem_types": "问题类型",
  "title": "标题",
  "describe": "描述",
  
  "email": "邮箱",
  "email_sub": "请留下联系邮箱以便接收问题解答",
  "submit": "提交",
  "please_enter_title": "请输入标题",
  "please_enter_content": "请输入内容",
  "please_enter_email": "请输入邮箱",
  "please_enter_phone_model": "请输入手机型号",
  "please_select_problem_types": "请选择问题类型",
  "sending_failure": "发送失败",
  "transfer_issue": "转账问题",
  "no_record_of_assets": "资产无记录",
  "payment_failure": "接收失败",
  "wallet_import": "钱包导入",
  "hardware_wallet":"硬件钱包",
  "backup_mnemonic":"备份助记词",
  "third_party_issues":"第三方应用问题",
  "other":"其他",
  "please_describe_recommendations":"请描述您对产品功能的相关建议",
  "please_introduce_cooperate":"请介绍贵公司并阐述期望合作的内容",
  "tips_for_successful_training":"提交成功，感谢您的关注与支持，我们会认真处理您的反馈。",
  "before":"以前",
  "cooperation_describe_sub":"请介绍贵公司并阐述期望合作的内容",
  "issue_describe_sub":"请详细描述您遇到的问题，我们的客服人员将尽快答复。",
  "suggest_describe_sub": "请描述您对产品功能的相关建议",
  "email_format_error":"邮箱格式错误",
  "upload_screenshot":"上传截图",
  "upload_screenshot_sub":"在此处上传截图作为辅助说明，图片格式支持PNG/JPG，最多可上传3张，每张大小不超过5MB。"
}
