export interface Options<T = string> {
  label: string;
  value: T;
}

export enum Lang {
  Zh = "zh",
  En = "en",
}

export const languages: Lang[] = [Lang.En, Lang.Zh];
