import { createApp } from "vue";
import App from "./App.vue";
import router from "./router";
import "@/style/index.scss";
import i18n from "./i18n";
import ElementPlus from "element-plus";
import "element-plus/lib/theme-chalk/index.css";
// import "element-plus/es/components/message/style/css";
const app = createApp(App);
app.use(ElementPlus).use(i18n).use(router).mount("#app");
