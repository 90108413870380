
import { defineComponent } from "vue";
import Header from "@/components/Header/index.vue";
import Footer from "@/components/Footer/index.vue";

export default defineComponent({
  name: "App",
  components: {
    Header,
    Footer,
  },
});
