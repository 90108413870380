import { Lang, languages } from "@/types/lang";
import { createI18n, LocaleMessages, VueMessageType } from "vue-i18n";

/**
 * Load locale messages
 *
 * The loaded `JSON` locale messages is pre-compiled by `@intlify/vue-i18n-loader`, which is integrated into `vue-cli-plugin-i18n`.
 * See: https://github.com/intlify/vue-i18n-loader#rocket-i18n-resource-pre-compilation
 */
function loadLocaleMessages(): LocaleMessages<VueMessageType> {
  const locales = require.context(
    "./locales",
    true,
    /[A-Za-z0-9-_,\s]+\.json$/i
  );
  const messages: LocaleMessages<VueMessageType> = {};
  locales.keys().forEach((key) => {
    const matched = key.match(/([A-Za-z0-9-_]+)\./i);
    if (matched && matched.length > 1) {
      const locale = matched[1];
      messages[locale] = locales(key).default;
    }
  });
  return messages;
}

let isUrlLang = false;

const getLangFromUrl = (): Lang | "" => {
  const url = new URL(location.href);
  const urlLang = url.searchParams.get("lang") as Lang;
  if (urlLang && languages.includes(urlLang)) {
    isUrlLang = true;
    return urlLang;
  }
  return "";
};

const saveLang = (lang: Lang): void => {
  localStorage.setItem("feedback-lang", lang);
};

export const changLang = (lang: Lang): void => {
  saveLang(lang);
  if (isUrlLang) {
    // remove lang of url params
    const url = new URL(location.href);
    url.searchParams.delete("lang");
    history.replaceState({}, document.title, `${url.pathname}${url.search}`);
  }
};

/**
 * 按照优先级设置语言
 * 1.url参数 lang
 * 2.本地缓存的语言
 * 3.浏览器的语言
 * 4.默认en
 */
const loadCurrentLang = (): Lang => {
  const urlLang = getLangFromUrl();
  if (urlLang) {
    saveLang(urlLang);
    return urlLang;
  }
  const localLang = localStorage.getItem("feedback-lang");
  if (localLang) {
    return localLang as Lang;
  }
  // const browserLanguages = navigator.languages;
  // if (browserLanguages[0] === "zh-CN" || browserLanguages[0] === "zh") {
  //   return Lang.Zh;
  // }
  return Lang.En;
};

const defaultLang = loadCurrentLang();

export default createI18n({
  legacy: true,
  locale: defaultLang,
  fallbackLocale: "en",
  messages: loadLocaleMessages(),
});
