<template>
  <footer class="g-safe-area">
    <div class="welcome">Welcome to ONTO Support.</div>
    <div class="contact">
      <!-- <div class="wechat">
        <div class="popup">
          <img src="./img/wechat.jpg" alt="" />
        </div>
      </div> -->
      <a class="telegram" href="https://t.me/ONTOWallet" target="_blank"></a>
    </div>
  </footer>
</template>

<script lang="ts">
import { defineComponent } from "vue";

export default defineComponent({
  name: "Footer",
});
</script>

<style scoped lang="scss">
footer {
  display: flex;
  justify-content: space-between;
  align-items: flex-end;
  padding-bottom: 100px;
  padding-top: 100px;
  .welcome {
    max-width: 240px;
    color: #c6c8c9;
    font-size: 30px;
    font-style: normal;
    font-weight: bold;
    text-align: left;
  }
  .contact {
    display: flex;
    .wechat {
      height: 32px;
      width: 32px;
      background: url("./img/wechat_nomal.svg") no-repeat;
      background-size: 100% 100%;
      position: relative;
      .popup {
        width: 120px;
        height: 120px;
        position: absolute;
        background-color: #fff;
        border-radius: 7px;
        top: -140px;
        left: 50%;
        transform: translate(-50%);
        display: none;
        img {
          width: 104px;
          height: 104px;
          position: absolute;
          top: 50%;
          left: 50%;
          transform: translate(-50%, -50%);
        }
      }
      .popup:after {
        display: block;
        content: "";
        border-width: 14px 14px 14px 14px;
        border-style: solid;
        border-color: #fff transparent transparent transparent;
        position: absolute;
        left: 40%;
        top: 100%;
      }
    }
    .wechat:hover {
      background: url("./img/wechat_hover.svg") no-repeat;
      background-size: 100% 100%;
      .popup {
        display: block;
      }
    }
    .telegram {
      height: 32px;
      width: 32px;
      margin-left: 24px;
      background: url("./img/telegram_nomal.svg") no-repeat;
      background-size: 100% 100%;
    }
    .telegram:hover {
      background: url("./img/telegram_hover.svg") no-repeat;
      background-size: 100% 100%;
    }
  }
}
</style>
