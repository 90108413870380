
// import LangSwitcher from "@/components/LangSwitcher.vue";
import { defineComponent } from "vue";
import { useI18n } from "vue-i18n";

export default defineComponent({
  name: "Header",
  setup() {
    const { t, locale } = useI18n();
    console.log(locale.value);
    const toBooks = () => {
      location.href =
        locale.value === "zh"
          ? "https://ontology-1.gitbook.io/onto-cn/"
          : "https://ontology-1.gitbook.io/onto/";
    };
    return {
      t,
      locale,
      toBooks,
    };
  },
  components: {},
});
