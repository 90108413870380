
import { defineComponent, ref } from "vue";
import { useI18n } from "vue-i18n";
import { useRouter } from "vue-router";
export default defineComponent({
  name: "Home",
  setup() {
    const { t } = useI18n();
    const radio = ref("issue");
    const router = useRouter();
    const next = () => {
      router.push(radio.value);
    };
    return { t, radio, next };
  },
});
