<template>
  <div class="home g-safe-area">
    <div class="g-title">
      <p>{{ t("help_center") }}</p>
    </div>
    <div class="g-container">
      <h5 class="g-h5">{{ t("select_what_you_want_to_feedback") }}</h5>
      <section class="g-section">
        <div class="g-radio-box">
          <el-radio v-model="radio" label="issue">{{
            t("use_problem")
          }}</el-radio>
          <el-radio v-model="radio" label="suggest">{{
            t("feature_suggestions")
          }}</el-radio>
          <el-radio v-model="radio" label="cooperation">{{
            t("business_cooperation")
          }}</el-radio>
        </div>
        <div class="next">
          <img
            class="next__img"
            src="../../assets/img/arrow-right.svg"
            @click="next"
            alt=""
          />
        </div>
      </section>
    </div>
  </div>
</template>
<script lang="ts">
import { defineComponent, ref } from "vue";
import { useI18n } from "vue-i18n";
import { useRouter } from "vue-router";
export default defineComponent({
  name: "Home",
  setup() {
    const { t } = useI18n();
    const radio = ref("issue");
    const router = useRouter();
    const next = () => {
      router.push(radio.value);
    };
    return { t, radio, next };
  },
});
</script>
<style scoped lang="scss">
.home {
  .next {
    margin-top: 18px;
    text-align: right;
  }

  .next__img {
    height: 12px;
    cursor: pointer;
    -webkit-user-drag: none;
  }
}
</style>
